<template>
  <div class="enquiry" style="background-image: url('/img/img-enquiry-1.png'); background-size: cover;" >
    <div class="content-wrapper">
      <div class="title medium-title font-white  bold">Are you The Next Campaign Planner Hero?</div>
      <div class="desc-enquiry font-white regular-font">{{heroDesc}}</div>
      <a :href="contactUrl" target="_blank">
        <button class="secondary-btn medium-bold enquiry-btn" style="cursor:pointer">Make Enquiry </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'adx-enquiry-section',
  props: {
   
  },
  data() {
    return {
      heroDesc: 'Join with our satisfied client to craft successful campaign stories that matter.',
      contactUrl: "https://m5.gs/RUlSND"
    }
  },
  computed:{
   
  },
  mounted(){
    
  }, 
  beforeUnmount() {
   
  },
  methods :{
   
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
