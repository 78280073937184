<template>
  <div class="project-card-wrapper" >
    <div class="items" v-for="(item, index) in project" :key="index">
      <div class="project-card" :style="`background-image: url(${item.url})`">
        <div class="brand-header font-black regular-font bold">{{item.brand}}</div>
        <div class="brand-footer font-white bold">{{item.type}}</div>
        <div class="overlay">
          <div class="title bold font-white">{{item.typeDetail}}</div>
          <div class="desc regular-font font-white">{{item.desc}}</div>
          <div class="subtitle regular-font font-white">{{item.subDesc}}</div>
        </div>
      </div>
    </div>
  </div>
</template>x

<script>
export default {
  name: 'adx-projects',
  props: {
  
  },
  data() {
    return {
      project: [],
      items: []
    }
  },
  computed:{
   
  },
  watch:{
    $route(){
      const routerName = this.$route.name
      this.getProjectItem(routerName)
    }
  }, 
  created(){
    let file = `/static/projects.json`;
    (async () => {
      let request = await fetch(new Request(file))
      let data = await request.json()
      
      this.items = data
      const routerName = this.$route.name
      if(routerName)this.getProjectItem(routerName)
    })()
  },
  methods: {
    getProjectItem(routerName){
      if(routerName == "metrics") {
        this.project = this.items.filter(it => it.project == "metrics")
      }else if (routerName == "origami"){
        this.project = this.items.filter(it => it.project == "origami")
      } else{
        this.project = this.items
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
