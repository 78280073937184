<template>
  <div class="home">
    <adx-header id="adx-header"></adx-header>
    <div class="home_banner">
      <div class="banner-title">
        <adx-single-text-slider
          :slides="slideContents"
          :mainTitleStyle="titleStyle"
        />
      </div>
    </div>
    <!-- Greetings -->
    <div class="home_section-greeting">
      <div class="greeting_top">
        <div class="title medium-title bold">Greetings from ADX Asia!</div>
        <span class="font-gray regular-font desc"
          >We empower out-of-home experience through technology and innovation
          at scale - from project planning, executing,
          monitoring, and measurement. <br />
          With ADX Asia, delivering your campaign will be more
          <b class="font-black bold">effective</b>, more
          <b class="font-black bold">efficient</b>, and more
          <b class="font-black bold">scalable</b> than ever before.</span
        >
      </div>
      <a href="/about-us">
        <button class="primary-btn" style="cursor: pointer">
          About ADX Asia
        </button>
      </a>
    </div>
    <!-- Section product -->
    <div class="home_product">
      <div class="product-container">
        <div class="title medium-title bold">Our Products</div>
        <div class="home_product_wrapper">
          <div class="card">
            <div class="line-red"></div>
            <div class="content">
              <div class="small-title title bold">Metrics</div>
              <div class="desc-product font-gray regular-font">
                One stop solution for
                <b class="font-red bold">large-format media </b> (billboard,
                videotron, 
                baliho, etc) from media planning, buying, to measurement and analysis.
              </div>
              <div class="learn-more">
                <a class="learn-button" href="/metrics" target="_blank">
                  <div class="more-btn font-red medium-bold regular-font">
                    Learn Metrics
                  </div>
                  <div class="icon-more">
                    <img src="@/assets/icon/icons8-arrow-24.png" alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="line-yellow"></div>
            <div class="content">
              <div class="small-title title bold">Origami</div>
              <div class="desc-product font-gray regular-font">
                Tech driven, one stop for
                <b class="font-yellow bold">operational solution</b>
                for on-ground marketing campaign, engagement marketing, and other
                operational aspect of your business
              </div>
              <div class="learn-more">
                <a class="learn-button" href="/origami" target="_blank">
                  <div class="more-btn font-red medium-bold regular-font">
                    Learn Origami
                  </div>
                  <div class="icon-more">
                    <img src="@/assets/icon/icons8-arrow-24.png" alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section activity -->
    <div class="home_activity">
      <div class="title medium-title bold">What We’ve Been Doing</div>
      <div class="desc-activity">
        <div class="sub-desc font-gray regular-font">
          We deliver more than 2,000+ campaigns with diverse out-of-home
          technology since 2016 around Indonesia.
        </div>
        <a href="/our-works" class="works-hd-btn">
          <button class="primary-btn medium-bold" style="cursor: pointer">
            View our works
          </button>
        </a>
      </div>
      <div class="activity-card-wrapper">
        <div v-for="(item, index) in items" :key="index" class="activity-card">
          <div class="img-thumbhnail" @click="gotoCaseStudy(item.brand)">
            <img :src="item.img" alt="" />
          </div>
          <div class="regular-font bold item-title">{{ item.title }}</div>
          <div class="desc-item font-gray regular-font">{{ item.desc }}</div>
          <div
            class="more-btn font-red medium-bold regular-font"
            @click="gotoCaseStudy(item.brand)"
          >
            View case study
          </div>
        </div>
        <a href="/our-works" class="works-sm-btn">
          <button class="primary-btn medium-bold" style="cursor: pointer">
            View our works
          </button>
        </a>
      </div>
    </div>
    <!-- Section Testimony -->
    <div class="home_testimony">
      <div class="content-wrapper">
          <img class="quote" src="@/assets/icon/baseline-format-quote-black-48-dp.png" alt=""/>
          <div class="carosell-text">
            <div class="title medium-title bold">What They Said</div>
             <adx-testimonial-slider
              :slides="testimonySlide"
              :textStyle="testimonyStyle"
              :footerStyle="testimonyFooterStyle"/>
          </div>
      </div>
    </div>
    <!-- Section hero -->
    <adx-enquiry-section />
    <adx-footer></adx-footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import SingleTextSlider from "@/components/SingleTextSlider";
import TestimonialSlider from "@/components/TestimonialSlider";
import EnquirySection from "@/components/EnquirySection";

export default {
  name: "home-page",
  components: {
    "adx-header": Header,
    "adx-footer": Footer,
    "adx-single-text-slider": SingleTextSlider,
    "adx-testimonial-slider": TestimonialSlider,
    "adx-enquiry-section": EnquirySection,
  },
  props: {},
  data() {
    return {
      items: [
        {
          img: "img/case-study-linkaja/case-study-linkaja@2x.png",
          title: "Linkaja - Billboard",
          brand: "linkaja",
          desc: "Providing OOH placement for LinkAja in 10 spots around Jabodetabek for 3 months period.",
        },
        {
          img: "img/case-study-realfood/case-study-realfood@2x.png",
          title: "Realfood - Billboard",
          brand: "realfood",
          desc: "Providing OOH placement for Realfood in 8 spots around Jakarta, Surabaya, Bekasi, Tangerang area.",
        },
        {
          img: "img/case-study-frisian-flag/case-study-frisian-flag@2x.webp",
          title: "Frisian Flag - Activation",
          brand: "frisianflag",
          desc: "Providing a customer engagement marketing activities for Frisian Flag at 2,500+ locations around big cities of Java.",
        },
        {
          img: "img/case-study-aqua/case-study-aqua@2x.png",
          title: "Aqua - Branding Implementation",
          brand: "aqua",
          desc: "Providing end-to-end Point of Sales branding management nationwide for Aqua at 19,000+ retail stores in 200+ cities in Indonesia’s big provinces.",
        },
      ],
      slideContents: [
        {
          header: "ADX ASIA",
          title: "Providing You with A Smarter OOH Experience",
          urlImg: "/img/home-page/slider-1/rectangle.png",
        },
        {
          header: "METRICS",
          title: "Revolutionary OOH Advertising Based on Technology at Scale",
          urlImg: "/img/home-page/slider-2/rectangle.png",
        },
        {
          header: "ORIGAMI",
          title: "Operational Solution for Your Offline Marketing Activities",
          urlImg: "/img/home-page/slider-3/rectangle.png",
        },
      ],
      titleStyle: {
        "font-size": "56px",
      },
      // testimonySlide:[],
      testimonySlide: [
        {
          text: "“Media partner yang punya end to end service dengan kualitas yang outstanding! Salah satu poin kunci adalah: teknologi pengukuran dan report yg detail & insightful. Good job ADX Asia!”",
          footer: "Inesa Muliadi, Campaign Lead LinkAja",
          urlImg: '/img/testimony/Inesa Muliadi-Campaign Lead LinkAja.png'
        },
        {
          text: "“The way ADX Asia can listen to our objectives is what we need. And serving real-time visibility progress for our thousand spots of branding should be the gold standard!”",
          footer: "Edi Kurniawan, Head of Marketing Moladin",
          urlImg: '/img/testimony/Edi Moladin.jpg'
        },
        {
          text: "“A brand campaign will be delivered well if it is accompanied by a good media partner. And a good media partner will give you a right data and a wonderful experience while exploring the great-advertising industry, and it's been proven with ADX Asia's work.”",
          footer: "Fanny Octafiani, Brand Marketing Specialist of Realfood",
          urlImg: '/img/testimony/Fanny Realfood.jpg'
        },
        {
          text: "“It's been 2 years working with ADX, starting from events in Jakarta to almost all over Java. this proves that ADX has good responsibilities in various aspects. Fast response, good in weekly and monthly reporting and great problem solving made ADX becoming one of the most trusted partners we had.”",
          footer: "Riyad Jasa Putra, Local Activation Manager of Frisian Flag",
          urlImg: '/img/testimony/Riyad Jasa Putra-Frisian Flag1.jpg'
        },
      ],
      testimonyStyle: {
        "font-size": "20px",
        color: "black",
        "max-width": "100%",
        "font-style": "italic",
      },
      testimonyFooterStyle: {
        "font-size": "14px",
        color: "gray",
      },
    };
  },
  mounted() {
    
  },
  beforeUnmount() {
    
  },
  methods: {
    gotoCaseStudy(brand) {
      this.$router.push({
        name: "case-study",
        params: { brand: brand },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";

// .home_section-greeting {
//   margin-left: -250px;
//   margin-right: -250px;
// }
</style>
