<template>
  <div class="origami">
    <header>
      <div class="header_container">
        <div class="header_container__left">
          <a href="#" class="header_container__left__logo">
            <img src="@/assets/logo/origami-logo@2x.png" alt="ADX Asia" />
          </a>
        </div>
        <div class="header_container__right">
          <a :href="contactUrl" target="_blank">
            <button class="base-font medium-bold font-white background-blue" 
            :class="isSticky? 'primary-btn': 'secondary-btn'">
            Get in Touch
            </button>
          </a>
        </div>
        <transition name="heightTop"> </transition>
      </div>
    </header>
    <!-- Banner section -->
    <div class="origami_banner" style="background-image: url('/img/origami/origami-banner@2x.png')">
      <div class="row">
        <div class="col_left">
          <div class="large-title font-navy bold main-title">
            Ensure your plans well executed with Origami!
          </div>
          <div class="font-gray regular-font title-desc bold-500">
            No need to worry about managing your operational tasks because
            we’re here to be the solution for you. Executing tasks becomes <b class="font-black">easier</b>, 
            monitoring progress becomes <b class="font-black">faster</b>, and reporting becomes <b class="font-black">preciser</b>! 
          </div>
        </div>
        <div class="col_right">
          <img src="/img/origami/title-image.png" alt="" />
        </div>
      </div>
    </div>
    <!-- See How It Works Section-->
    <div class="origami__works">
      <div class="medium-title title font-navy bold">See How It Works</div>
      <div class="activity-card-wrapper"> 
      <div class="card">
        <div class="circle-icon circle-green">
          <img src="@/assets/icon/tree-white@2x.png" alt="" />
        </div>
        <!-- Content -->
        <div class="small-title font-navy bold title-works">Customize Workflow</div>
        <div class="font-gray regular-font desc-works bold-500">
          No need for complex task in creating workflow because ADX have an
          expert working system & supporting tools to provide you with the most
          efficient workflow for optimal results.
        </div>
      </div>
      <div class="card">
        <!-- Content -->
        <div class="circle-icon circle-blue">
          <img src="@/assets/icon/assignment-add-white@2x.png" alt="" />
        </div>
        <div class="small-title bold font-navy title-works">Assign & Execute</div>
        <div class="font-gray regular-font desc-works bold-500">
          Assign tasks personalized to the right workers. After assignment,
          tasks will appear on their cell phone, just like a to-do-list. Of
          course, our office team will validate and verify the work to ensure
          they meet the standard. So the execution process will be efficient and
          reducing waste of time.
        </div>
      </div>
      <div class="card">
        <!-- Content -->
        <div class="circle-icon circle-red">
          <img src="@/assets/icon/devices-white@2x.png" alt="" />
        </div>
        <div class="small-title bold font-navy title-works">Easy Monitoring</div>
        <div class="font-gray regular-font desc-works bold-500">
          Everything we do is technology driven. We plan, execute & verify
          everything. We’ve established digital tracking system and showed in
          real time monitoring dashboard to make this possible. <br />
          Ensure projects run on track, according to standard, and in timely
          manner.
        </div>
      </div>
      <div class="card">
        <!-- Content -->
        <div class="circle-icon circle-yellow">
          <img src="@/assets/icon/query-stats-white@2x.png" alt="" />
        </div>
        <div class="small-title bold font-navy title-works">Comprehensive Report</div>
        <div class="font-gray regular-font desc-works bold-500">
          Serving you the full exposure of your project results.
          <ul>
            <li>
              For branding project, our BAST is automatically generated by
              machine, with only one-click away.
            </li>
            <li>
              For activation project, we provide a comprehensive report that you
              could get, with data analysis & insights.
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
    <!-- What we can do for you? Section-->
    <div class="origami__feature">
      <div class="medium-title bold font-navy what-can-we-do">What we can do for you</div>
      <div class="row_do">
      <div class="col_do_left">
        <div class="desc_do">
          <div class="small-title font-navy bold title-feature">Branding Implementation</div>
          <div class="font-gray regular-font bold-500 desc">
            POSM-focused marketing campaign for your brand. We provides: <br />
            <div class="font-gray regular-font marketing">
              <ul class="bullet bold-500">
                  <li>Production</li>
                  <li>Distribution</li>
                  <li>Installation</li>
                  <li>Removal</li>
              </ul>
              <ul class="bullet1 bold-500" >
                <li>Field Survey</li>
                  <li>POSM Audit</li>
                  <li>Ads Tax Management</li>
                  <li>Gimmick Production</li>      
              </ul>   
            </div>
          </div>
        </div>
      </div>
      <div class="col_do_right">
        <img src="/img/origami/advertising@2x.png" alt="" />
      </div>
      </div>
      <div class="row_do eng-marketing">
        <div class="col_do_right">
          <div class="desc_do">
            <div class="small-title bold font-navy title-feature">Engagement Marketing</div>
            <div class="font-gray regular-font">Besides, we can do any other projects with heavy operational tasks!</div>
            <div class="font-gray regular-font">
              <ul class="marketing">
                <div class="bullet bold-500">
                  <li>Activation with SPG</li>
                  <li>Door to Door Promotion</li>
                  <li>Mobile Point of Sales</li>
                  <li>Manpower Management</li>
                </div>
                <div class="bullet1 bold-500">
                  <li>Lead Generation</li>
                  <li>Consumer Survey</li>
                  <li>Permit Management</li>
                  <li>Gimmick Channel Activation</li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="col_do_left">
          <img src="/img/origami/marketing@2x.png" alt="" />
        </div>
      </div>
      <div class="row_do">
        <div class="col_do_left">
          <div class="desc_do">
            <div class="small-title font-navy title-feature bold">Operational Task Tracking</div>
            <div class="font-gray regular-font bold-500 desc operational">
              Origami’s software as a service for enterprise / B2B. Here are some
              applied use-cases:
              <ul>
                <li>Land Acquisition Survey</li>
                <li>Invoice Progress Tracker</li>
                <li>Budget Realization Tracker</li>
                <span>Besides, we can do any other projects with heavy operational
                  tasks!</span>
              </ul>
            </div>
          </div>
        </div>
        <div class="col_do_right">
          <img src="/img/origami/operation@2x.png" alt="" />
        </div>
      </div>
    </div>
    <!-- Client Section -->
    <div class="origami__clients">
      <div class="medium-title bold title">Our satisfied client</div>
      <div class="client-list">
        <div v-for="(brand, index) in brands" :key="index" class="img-wrapper">
          <img :src="brand" alt=""/>
        </div>
      </div>
      <div class="work-btn pointer">
        <router-link :to="{ path: '/our-works', name: 'origami' }" target="_blank">
          <button  class="primary-btn font-white medium-bold regular-font background-blue">
          View Our Works
        </button>
        </router-link>
      </div>
    </div>
    <!-- Testimony -->
    <div class="origami__testimony">
      <div class="content-wrapper">
          <div class="carosell-text">
            <div class="title medium-title bold">What They Said</div>
             <adx-testimonial-slider
              :slides="testimonySlide"
              :textStyle="testimonyStyle"
              :footerStyle="testimonyFooterStyle"/>
          </div>
      </div>
    </div>
     <!-- Enquiry -->
    <div class="origami__enquiry">
      <div class="content-wrapper">
        <div class="title medium-title font-white bold">
          Start your journey with Origami!
        </div>
        <div class="desc-enquiry font-white regular-font bold-500">
          Scale up your project with the help of technology to optimize the result <br/> with Origami by ADX Asia
        </div>
        <a :href="contactUrl" target="_blank">
          <button class="secondary-btn medium-bold enquiry-btn">Get Started</button>
        </a>
      </div>
    </div>
    <footer>
      <div class="footer_container">
        <div class="address-detail">
          <img src="@/assets/logo/origami-logo@2x.png" alt="Origami" />
        </div>
        <div class="categories-wrapper">
          <div v-for="(category, index) in categories" :key="index" class="category">
            <div class="title small-title bold font-black">
              {{ category.title }}
            </div>
            <ul>
              <li
                v-for="(item, index) in category.items" :key="index">
                <a class="regular-font font-gray" 
                  :href="item.path" 
                  :target=" item.newTab? '_blank' :''">{{item.name}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copy-right regular-font font-gray bold-500">
        Copyright origami 2022. Part of
        <strong class="font-black">ADX Asia</strong>
      </div>
    </footer>
  </div>
</template>

<script>
import TestimonialSlider from "@/components/TestimonialSlider";

export default {
  name: "new-origami",
  watch: {
    $route: {
      immediate: true,
      handler(to){
        document.title = to.meta.title || "Origami";
        const link = document.querySelector("[rel='icon']");
        link.setAttribute('href',to.meta.icon|| "img/origami/favicon-32x32.png"); // change icon in the page, must in public folder.
      }
    }
  },
  components: {
    "adx-testimonial-slider": TestimonialSlider,
  },
  props: {},
  data() {
    return {
      contactUrl: "https://tinyurl.com/yzbxxs9j",
      isToggle: false,
      isSticky: false,
      testimonySlide: [
        {
          text: "““We have been partnering with ADX Asia for more than 3 years and we’re delighted towards the performance.The monitoring system & reporting are very professional, and the team is responsive & reliable. ADX Asia is truly bringing solution for our projects””",
          footer: "Frebriansyah H, Managing Director Eden Kreasi Indonesia",
          urlImg: '/img/testimony/febri.png'
        },
        {
          text: "“The way ADX Asia can listen to our objectives is what we need. And serving real-time visibility progress for our thousand spots of branding should be the gold standard!”",
          footer: "Edi Kurniawan, Head of Marketing Moladin",
          urlImg: '/img/testimony/Edi Moladin.jpg'
        },
        {
          text: "“It's been 2 years working with ADX, starting from events in Jakarta to almost all over Java. this proves that ADX has good responsibilities in various aspects. Fast response, good in weekly and monthly reporting and great problem solving made ADX becoming one of the most trusted partners we had.”",
          footer: "Riyad Jasa Putra, Local Activation Manager of Frisian Flag",
          urlImg: '/img/testimony/Riyad Jasa Putra-Frisian Flag1.jpg'
        },
      ],
      testimonyStyle: {
        "font-size": "20px",
        "color": "black",
        "max-width": "100%",
        "font-style": "italic",
        "font-weight": "400",
      },
      testimonyFooterStyle: {
        "font-size": "14px",
        "color": "gray",
      },
      categories: [
        {
          title: "All Product by ADX Asia",
          items: [
            {
              name: "ADX Asia" ,
              path: '/',
              newTab: true
            },
            {
              name: "Metrics" ,
              path: '/metrics',
              newTab: true
            },
            {
              name: "Origami" ,
              path: '/origami',
              newTab: false
            }
          ],
        },
        {
          title: "Resource",
          items: [
          {
              name: "Contact us" ,
              path: 'https://tinyurl.com/yzbxxs9j',
              newTab: true
            },
            {
              name: "My Account" ,
              path: 'https://theorigami.io',
              newTab: true
            }
          ],
        },
      ],
      brands:[
        "/img/our-works/partners/aqua.png",
        "/img/our-works/partners/FF.png",
        "/img/our-works/partners/tokopedia.jpg",
        "/img/our-works/partners/linkaja.png",
        "/img/our-works/partners/sosro.jpg",
        "/img/our-works/partners/mypertamina.png",
        "/img/our-works/partners/moladin.png",
        "/img/our-works/partners/wingsfood.jpg",
        "/img/our-works/partners/grab.png",
        "/img/our-works/partners/samsung.png",
        "/img/our-works/partners/ovo.jpg",
        "/img/our-works/partners/sampoernakayu.jpg",
        "/img/our-works/partners/adakami.jpg",
        "/img/our-works/partners/gojek.png",
        "/img/our-works/partners/grohe.png",
        "/img/our-works/partners/promag.png",
        "/img/our-works/partners/belimobilgue.jpg",
        "/img/our-works/partners/wahyoo.jpg"
      ]
    };
  },
  computed: {},
  mounted() {
    
  },
  beforeUnmount() {
    
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<style lang="scss">
@import "~@/styles/mixins";

.origami {
  .agile {
    &__dot {
      &--current, &:hover {
        button {
          background-color: #f2b705 !important;
          border: 1px solid #f2b705 !important;
        }
      }
    }
  }

  .agile--no-nav-buttons .agile__actions {
    justify-content: left;
  } 
}

@include breakpoint(sm) {
  .origami{
    .agile--no-nav-buttons .agile__actions {
      justify-content: center;
  } 
  }
}
</style>
