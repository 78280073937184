<template>
  <div class="metrics">
    <header>
      <div class="header_container">
        <div class="header_container__left">
          <a href="#" class="header_container__left__logo">
            <img src="@/assets/logo/metrics.png" alt="ADX Asia" />
          </a>
        </div>
        <div class="header_container__right">
          <a
            href="https://metricsbyadx.com/login"
            class="base-font bold font-white login-btn"
            :class="isSticky ? 'transparent-btn font-black' : ''"
            target="_blank"
            >Login</a
          >
          <a
            :href="contactUrl"
            target="_blank"
            class="base-font medium-bold font-white transparent-btn contact-btn"
            :class="isSticky ? 'primary-btn-origami' : 'secondary-btn'"
            >Contact Us</a
          >
        </div>
        <div class="header_container__mobile-menu" @click="showMenu">
          <img v-if="isSticky" src="@/assets/icon/menu-bar-black.png" />
          <img v-else src="@/assets/icon/menu-bar-white.png" />
        </div>
        <transition name="heightTop">
          <div class="sideBarMenu" v-if="isToggle">
            <div class="title bold font-white">
              <div>Menu</div>
              <div>
                <img
                  src="@/assets/icon/close-white.png"
                  alt=""
                  @click="showMenu"
                />
              </div>
            </div>
            <div class="menu">
              <div class="option">
                <a
                  href="https://metricsbyadx.com/login"
                  target="_blank"
                  class="regular-font bold font-white login-btn"
                  >Login</a
                >
                <a
                  :href="contactUrl"
                  target="_blank"
                  class="regular-font medium-bold font-white transparent-btn contact-btn primary-btn"
                  >Contact Us</a
                >
              </div>
            </div>
          </div>
        </transition>
      </div>
    </header>
    <div
      class="title-banner"
      style="background-image: url('/img/metrics/title_image/title-banner.png')"
    >
      <div class="title_text-div">
        <div class="large-title bold font-white title-text">
          Empowering Smarter Out-of-Home Experience through Technology and
          Innovation at Scale
        </div>
      </div>
      <hr class="divider-hr" />
      <div class="title_desc-div">
        <div class="title-desc font-white regular-font">
          We are a tech-enabled media start up with a mission to bring
          World-class Outdoor advertising by revolutionizing the ad industry
          from means of buying to solution and measurement.
        </div>
      </div>
    </div>
    <div class="mid-content">
      <div class="title-image">
        <img src="/img/metrics/title_image/group-5@2x.png" alt="" />
      </div>
      <div class="small-title bold title-slider-content">
        Move beyond conventional, free yourselves
        <hr class="divider-hr" />
      </div>
      <div class="slider">
        <metrics-slider :slides="slider"/>
      </div>
    </div>
    <div id="campaign-section" ref="campaign" class="campaign">
      <div class="small-title bold">The Smarter Campaign Loop</div>
      <hr class="divider-hr" />
      <div class="campaign_loop">
        <div class="loop-1">
          <img
            class="top-left-arrow"
            src="/img/metrics/top-left-loop/group-20@2x.png"
            alt=""
          />
          <div class="planning" @click="toggleModal(1)">
            <div class="loop-number font-white regular-font">{{campaignLoop[0].campaignNum}}</div>
            <div class="loop-title bold font-black">{{campaignLoop[0].campaignTitle}}</div>
            <div class="loop-desc font-gray regular-font">
              {{campaignLoop[0].campaignDesc}}
            </div>
            <div class="learn-btn font-red regular-font bold-600">
              Learn more
            </div>
          </div>
          <img
            class="top-right-arrow"
            src="/img/metrics/top-right-loop/group-20@2x.png"
            alt=""
          />
        </div>
        <div class="loop-2">
          <div class="planning num-4" @click="toggleModal(4)">
            <div class="loop-number font-white regular-font">{{campaignLoop[3].campaignNum}}</div>
            <div class="loop-title bold font-black">
              {{campaignLoop[3].campaignTitle}}
            </div>
            <div class="loop-desc font-gray regular-font">
              {{campaignLoop[3].campaignDesc}}
            </div>
            <div class="learn-btn font-red regular-font bold-600">
              Learn more
            </div>
          </div>
          <div class="planning" @click="toggleModal(2)">
            <div class="loop-number font-white regular-font">{{campaignLoop[1].campaignNum}}</div>
            <div class="loop-title bold font-black">
              {{campaignLoop[1].campaignTitle}}
            </div>
            <div class="loop-desc font-gray regular-font">
              {{campaignLoop[1].campaignDesc}}
            </div>
            <div class="learn-btn font-red regular-font bold-600">
              Learn more
            </div>
          </div>
        </div>
        <div class="loop-3">
          <img
            class="bot-left-arrow"
            src="/img/metrics/bot-left-loop/group-20@2x.png"
            alt=""
          />
          <div class="planning" @click="toggleModal(3)">
            <div class="loop-number font-white regular-font">{{campaignLoop[2].campaignNum}}</div>
            <div class="loop-title bold font-black">
              {{campaignLoop[2].campaignTitle}}
            </div>
            <div class="loop-desc font-gray regular-font">
              {{campaignLoop[2].campaignDesc}}
            </div>
            <div class="learn-btn font-red regular-font bold-600">
              Learn more
            </div>
          </div>
          <img
            class="bot-right-arrow"
            src="/img/metrics/bot-right-loop/group-20@2x.png"
            alt=""
          />
        </div>
        <div class="planning num-4-mobile" @click="toggleModal(4)">
          <div class="loop-number font-white regular-font">4</div>
          <div class="loop-title bold font-black">
            Smarter Learning, Data-driven
          </div>
          <div class="loop-desc font-gray regular-font">
            We scale up what worked and scrap what did not. Then, we create a
            better campaign next time.
          </div>
          <div class="learn-btn font-red regular-font bold-600">Learn more</div>
        </div>
      </div>
    </div>

    <div class="clients">
      <div class="small-title bold">Our Satisfied Clients</div>
      <hr class="divider-hr" />
      <div style="display: flex; justify-content: center">
        <div class="client-list">
          <img
            v-for="(client, index) in clients"
            :key="index"
            :src="client"
            alt=""
          />
        </div>
      </div>
      <div class="work-btn">
        <router-link
          :to="{ path: '/our-works', name: 'metrics' }"
          target="_blank"
        >
          <button
            class="primary-btn font-white medium-bold regular-font background-metrics"
            style="cursor: pointer"
          >
            View Our Works
          </button>
        </router-link>
      </div>

    </div>
    <div class="testimony">
      <div class="content-wrapper">
        <div class="left-content">
          <img
            class="quote"
            src="@/assets/icon/baseline-format-quote-black-48-dp.png"
            alt=""
          />
          <div class="carosell-text">
            <div class="title medium-title bold">What They Said</div>

            <adx-testimonial-slider
              :slides="testimonySlide"
              :textStyle="testimonyStyle"
              :footerStyle="testimonyFooterStyle"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="enquiry" style="background-color: #f83e62">
      <div class="content-wrapper">
        <div class="title medium-title font-white bold">
          Are you the next campaign planner hero?
        </div>
        <div class="font-white regular-font medium-bold enquiry-desc">
          Join our satisfied clients who already use Metrics to craft successful
          campaign using smarter OOH advertising with technology at scale.
        </div>
        <a :href="contactUrl" target="_blank">
          <button
            class="secondary-btn bold enquiry_btn transparent-btn font-white"
          >
            Yes, and Make Enquiry
          </button>
        </a>
      </div>
    </div>

    <footer>
      <div class="footer_container">
        <div class="address-detail">
          <img src="@/assets/logo/metrics-normal.png" alt="Origami" />
        </div>
        <div class="categories-wrapper">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category"
          >
            <div class="title small-title bold font-black">
              {{ category.title }}
            </div>
            <ul>
              <li
                v-for="(item, index) in category.items"
                :key="index"
                class="regular-font font-gray medium-bold"
              >
                <a
                  class="regular-font font-gray"
                  :href="item.path"
                  :target="item.newTab ? '_blank' : ''"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copy-right regular-font font-gray bold-600">
        Copyright Metrics 2022. Part of
        <strong class="font-metrics">ADX Asia</strong>
      </div>
    </footer>
    <metrics-modal v-if="isModalOpen" @modal-close="closeModal" :type="'full'">
      <template v-slot:content>
        <div class="content">
          <div class="margin-section content__title">
            <div class="number">
              <span>{{ itemModal.content_index }}</span>
            </div>
            <div>
              <h1 class="text regular-font text-style-1 bold">
                {{ itemModal.title }}
              </h1>
            </div>
          </div>
          <div class="content__bottom">
            <div class="content__bottom__left">
              <div class="img-one">
                <img :src="itemModal.imgDekstop" class="desktop" alt="" />
              </div>
              <div
                v-if="itemModal.content_index == 2"
                :class="[
                  itemModal.subContent
                    ? 'img-two-modal-2-2'
                    : 'img-two-modal-2',
                ]"
              >
                <img
                  :src="itemModal.imgContent"
                  :class="[
                    itemModal.subContent ? 'img-content-1' : 'img-content',
                  ]"
                  alt=""
                />
                <img
                  :src="itemModal.imgContent2"
                  :class="[
                    itemModal.subContent ? 'img-content-2' : 'img-content',
                  ]"
                  alt=""
                />
              </div>
              <div v-if="itemModal.content_index == 3" class="img-two-modal-3">
                <img :src="itemModal.imgContent" class="img-content" alt="" />
                <img
                  :src="itemModal.imgContent2"
                  class="img-content-2"
                  alt=""
                />
              </div>
              <div
                v-else-if="itemModal.content_index == 4"
                class="img-two-modal-4"
              >
                <div class="img-two-modal-4__wrapper">
                  <div class="img-content">
                    <img
                      :src="itemModal.imgContent"
                      class="img-content"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="text-1">
                      <h1 class="regular-font bold">Smarter placement</h1>
                    </div>
                    <div class="text-2">
                      <h1 class="regular-font bold">Smarter message</h1>
                    </div>
                    <div class="text-3">
                      <h1 class="regular-font bold">Smarter partnership</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="itemModal.content_index == 1" class="img-two-modal-1">
                <img :src="itemModal.imgContent" class="img-content" alt="" />
              </div>
            </div>
            <div class="content__bottom__right">
              <div class="sub-title margin-2">
                <h1 class="text-style-2 regular-font bold" style="line-height: 1.33;">
                  {{ itemModal.desc }}
                </h1>
              </div>
              <div class="point-desc">
                <ul class="list">
                  <li
                    v-for="(point, index) in itemModal.pointDesc"
                    :key="index"
                  >
                    <span class="text-style-1 regular-font">
                      {{
                        point.pointTitle ? point.pointTitle : point.text
                      }}</span
                    >
                    <div class="sub-point" v-if="point.pointTitle">
                      <span class="text-style-1 regular-font">
                        {{ point.text }}</span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="allItemModal.item.length > 1" class="button-control">
                <div
                  class="before space pointer-mouse"
                  @click="backNext(allItemModal.item[0])"
                >
                  <img
                    class="next-prev-btn"
                    src="@/assets/icon/chevron-left-black.png"
                    alt=""
                  />
                </div>
                <div
                  class="next pointer-mouse"
                  @click="backNext(allItemModal.item[1])"
                >
                  <img
                    class="next-prev-btn"
                    src="@/assets/icon/chevron-right-black.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </metrics-modal>
  </div>
</template>

<script>
import TestimonialSlider from "@/components/TestimonialSlider";
import Modal from "@/components/Modal";
import Slider from "@/components/MetricsSlider";

export default {
  name: "new-metrics",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Metrics";
        const link = document.querySelector("[rel='icon']");
        link.setAttribute("href", to.meta.icon || "img/metrics/favicon.ico"); // change icon in the page, must in public folder.
      },
    },
  },
  components: {
    "adx-testimonial-slider": TestimonialSlider,
    "metrics-modal": Modal,
    "metrics-slider": Slider,
  },
  setup() {
    return {};
  },
  props: {},
  data() {
    return {
      contactUrl:"https://tinyurl.com/ye7hyk57",
      isToggle: false,
      isSticky: false,
      isModalOpen: false,
      itemModal: null,
      allItemModal: [],
      slider: [
        {
          slideImg: "/img/metrics/slide-img/carousel-img-1.png",
          slideNum: "1",
          slideTitle: "From feeling driven to data driven",
          slideDesc:
            "With single access point for various databases technology",
        },
        {
          slideImg: "/img/metrics/slide-img/group-19@2x.png",
          slideNum: "2",
          slideTitle: "From guts based to objective based placement",
          slideDesc:
            "With comprehensive analysis through advanced measurement technology & insights",
        },
        {
          slideImg: "/img/metrics/slide-img/carousel-img-3.png",
          slideNum: "3",
          slideTitle:
            "From doing same thing over and over to doing better than ever",
          slideDesc:
            "We help you find the right channel to show off your creative ads.",
        },
        {
          slideImg: "/img/metrics/slide-img/carousel-img-4-1.jpg",
          slideNum: "4",
          slideTitle: "From complicated process to convenient process",
          slideDesc:
            "With all in one operation process from proof-print, placement, re-skin, etc.",
        },
        {
          slideImg: "/img/metrics/slide-img/carousel-img-5-1.png",
          slideNum: "5",
          slideTitle: "From wasting money to saving money",
          slideDesc:
            "Fast coordination process with best prices & open to any special request.",
        },
      ],
      campaignLoop: [
        {
          campaignNum: 1,
          campaignTitle: "Smarter Planning",
          campaignDesc: "Discover best location for your campaign. Get to know how your competitor's campaign."
        },
        {
          campaignNum: 2,
          campaignTitle: "Smarter Implementation & Insight",
          campaignDesc: "Get insight into your audience through Computer Vision (CV) and Movement & Location Analytics (MLA)."
        },
        {
          campaignNum: 3,
          campaignTitle: "Smarter Impact Measurement",
          campaignDesc: "Help us help you! Map the impact of your campaign to user behaviour such as new downloads, usage frequency, and basket size."
        },
        {
          campaignNum: 4,
          campaignTitle: "Smarter Learning, Data-driven",
          campaignDesc: "We scale up what worked and scrap what did not. Then, we create a better campaign next time."
        },
      ],
      clients: [
        "/img/our-works/partners/linkaja.png",
        "/img/our-works/partners/blibli.png",
        "/img/our-works/partners/scarlett.png",
        "/img/our-works/partners/pluang.jpg",
        "/img/our-works/partners/adakami.jpg",
        "/img/our-works/partners/bhinneka.png",
        "/img/our-works/partners/realfood.png",
        "/img/our-works/partners/garmin.png",
        "/img/our-works/partners/paragon.jpg",
        "/img/our-works/partners/belimobilgue.jpg",
        "/img/our-works/partners/nagata.jpg",
        "/img/our-works/partners/alga.jpg",
        "/img/our-works/partners/paramount.png",
        "/img/our-works/partners/travelio.jpg",
        "/img/our-works/partners/venom.jpg",
        "/img/our-works/partners/jis.png",
        "/img/our-works/partners/zamron.jpg",
      ],
      testimonySlide: [
        {
          text: "“Media partner yang punya end to end service dengan kualitas yang outstanding! Salah satu poin kunci adalah: teknologi pengukuran dan report yg detail & insightful. Good job ADX Asia!”",
          footer: "Inesa Muliadi, Campaign Lead LinkAja",
          urlImg: "/img/testimony/Inesa Muliadi-Campaign Lead LinkAja.png",
        },
        {
          text: "“A brand campaign will be delivered well if it is accompanied by a good media partner. And a good media partner will give you a right data and a wonderful experience while exploring the great-advertising industry, and it's been proven with ADX Asia's work.”",
          footer: "Fanny Octafiani, Brand Marketing Specialist of Realfood",
          urlImg: "/img/testimony/Fanny Realfood.jpg",
        },
        {
          text: "“Media partner yang dijalankan oleh orang2 yg kompeten, memberikan services terbaik dgn advanced technology innovation dalam penerapan planning & implementasi OOH sehingga dapat memberikan report placement yang akurat. Kami tenang, client senang!”",
          footer: "Apink Widyasmoko, Managing Director Netlink World Indonesia",
          urlImg: "/img/testimony/apink.png",
        },
      ],
      testimonyStyle: {
        "font-size": "20px",
        color: "black",
        "max-width": "100%",
        "font-style": "italic",
      },
      testimonyFooterStyle: {
        "font-size": "14px",
        color: "gray",
      },
      categories: [
        {
          title: "All Product by ADX Asia",
          items: [
            {
              name: "ADX Asia",
              path: "/",
              newTab: true,
            },
            {
              name: "Metrics",
              path: "/metrics",
              newTab: false,
            },
            {
              name: "Origami",
              path: "/origami",
              newTab: true,
            },
          ],
        },
        {
          title: "Resource",
          items: [
            {
              name: "Contact Us",
              path: "https://tinyurl.com/ye7hyk57",
              newTab: true,
            },
            {
              name: "My Account",
              path: "https://metricsbyadx.com/login",
              newTab: true,
            },
          ],
        },
      ],
      modalContent: [
        {
          item: [
            {
              content_index: 1,
              title: "Smarter Planning (1/2)",
              imgDekstop: "img/metrics/campaign/desktop-blue.png",
              imgContent: "img/metrics/campaign/content-1.png",
              desc: "Plan better with our Insight Map.",
              pointDesc: [
                {
                  text: "See recommended routes and area in 38 cities accross Indonesia. Be a local without ever going there.",
                },
                {
                  text: "We pre-selected recommended locations for you based on traffic, viewability, and size. We got your back.",
                },
                {
                  text: "We verified each bit of information for you. So you can focus on what you do best: business and marketing. ",
                },
              ],
            },
            {
              content_index: 1,
              title: "Smarter Planning (2/2)",
              imgDekstop: "img/metrics/campaign/desktop-yellow.png",
              imgContent: "img/metrics/campaign/content-1-2.png",
              desc: "Know your competition: Share of Voice (SoV) Map.",
              pointDesc: [
                {
                  text: "Get insight about your competitors activities. See where they have put ad, how many, and what's the message.",
                },
                {
                  text: "Armed with above information, we will help you mount timely and appropriate response.",
                },
                {
                  text: "Wise man once said: If you know the enemy and know yourself, you need not fear the result of a hundred battles.",
                },
              ],
            },
          ],
        },
        {
          item: [
            {
              content_index: 2,
              title: "Smarter Implementation & Insight (1/2)",
              imgDekstop: "img/metrics/campaign/desktop-red.png",
              imgContent: "img/metrics/campaign/content-2.png",
              imgContent2: "img/metrics/campaign/content-2-1.png",
              desc: "Discover audience distribution and calculate maximum exposure.",
              pointDesc: [
                {
                  text: "We deploy cameras to every billboard of your choice to count reach, impression, and frequency, as well as vehicle type and model.",
                },
                {
                  text: "We use Movement and Location Analytics (MLA) to profile your campaign audience near the OOH location.",
                },
                {
                  text: "The result: accurate count of exposure.",
                },
              ],
            },
            {
              content_index: 2,
              subContent: true,
              title: "Smarter Implementation & Insight (2/2)",
              imgDekstop: "img/metrics/campaign/desktop-red.png",
              imgContent: "img/metrics/campaign/bus-monitoring-detail.png",
              imgContent2:
                "img/metrics/campaign/digital-screen-monitoring-detail.png",
              desc: "Monitor your campaign, safeguard your assets.",
              pointDesc: [
                {
                  text: "We take picture of your OOH placement every two weeks, just to make sure everything is in good condition.",
                },
                {
                  text: "Pictures will be uploaded to a dashboard, where you can log in and see.",
                },
                {
                  text: "We do this to make sure you get what rightfully yours.",
                },
              ],
            },
          ],
        },
        {
          item: [
            {
              content_index: 3,
              title: "Smarter Impact Measurement",
              imgDekstop: "img/metrics/campaign/desktop-green.png",
              imgContent: "img/metrics/campaign/bb-report-impact.png",
              imgContent2: "img/metrics/campaign/content-3-1.png",
              desc: "See if your campaign has any impacts toward user adoption and behavior.",
              pointDesc: [
                {
                  pointTitle: "Adoption",
                  text: "Check if the exposed audience download your application.",
                },
                {
                  pointTitle: "Frequency",
                  text: "Does your campaign make users use your application more? Lets find out.",
                },
                {
                  pointTitle: "Basket size",
                  text: "We hope that your campaign make your users trust you more, as indicated by increased basket size.",
                },
              ],
            },
          ],
        },
        {
          item: [
            {
              content_index: 4,
              title: "Smarter Learning, Data-driven",
              imgDekstop: "img/metrics/campaign/group-yellow-2.png",
              imgContent: "img/metrics/campaign/content-4.png",
              desc: "Learn from the past, prepare for the future.",
              pointDesc: [
                {
                  pointTitle: "Smarter placement",
                  text: "Focus your next campaign to active vs non-active users.",
                },
                {
                  pointTitle: "Smarter message",
                  text: "Create specific creative and message to boost basket size or frequency",
                },
                {
                  pointTitle: "Smarter partnership",
                  text: "Focus your promotion on specific merchants based on area.",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    goToOurWork() {
      let route = this.$router.resolve({ path: "/our-works", name: "metrics" });
      window.open(route.href, "_blank");
    },
    showMenu() {
      this.isToggle = !this.isToggle;
    },
    closeModal() {
      this.isModalOpen = false;
      document.body.style.overflow = "visible";
      this.$refs["campaign"].scrollIntoView(true);
    },
    toggleModal(value) {
      this.isModalOpen = true;
      let content = value;
      if (content == 1) {
        this.itemModal = this.modalContent[0].item[0];
        this.allItemModal = this.modalContent[0];
      } else if (content == 2) {
        this.itemModal = this.modalContent[1].item[0];
        this.allItemModal = this.modalContent[1];
      } else if (content == 3) {
        this.itemModal = this.modalContent[2].item[0];
        this.allItemModal = this.modalContent[2];
      } else if (content == 4) {
        this.itemModal = this.modalContent[3].item[0];
        this.allItemModal = this.modalContent[3];
      }
    },
    backNext(value) {
      this.itemModal = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
