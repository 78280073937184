<template>
  <div class="our-works">
    <adx-header id="adx-header"></adx-header>
    <div class="our-works_banner" style="background-image: url('/img/our-works/rectangle@2x.png')">
      <div class="banner-title">
          <div class="regular-font font-white our-works-title">OUR WORKS</div>
          <div class="large-title font-white bold our-works-desc">What We've Been Doing?</div>
      </div>
    </div>
    <div class="our-works_latest-project">
      <div class="title font-black bold medium-title">Recent Project</div>
      <div class="project-navigate">
        <ul class="wrapper">
          <li class="font-gray regular-font bold">
            <router-link :to="{name: 'works-allprojects'}">All Project</router-link>
          </li>
          <li class="font-gray regular-font bold">
            <router-link :to="{name: 'metrics'}">Metrics</router-link>
          </li>
          <li class="font-gray regular-font bold">
            <router-link :to="{name: 'origami'}">Origami</router-link>
          </li>
        </ul>
      </div>
      <router-view />
      <div class="text-desc">
        <span class="font-gray regular-font">ADX Asia is trusted by more than 100 brands in Indonesia. 
          With our technology and innovation, we are here to serve you with a smarter Out-of-Home experience 
          with measurable and satisfying results for your campaign. Let’s join us to be part of the future!</span>
      </div>
      <div class="partners-logo">
        <div class="logo-wrapper">
          <img :class="['default-img']" 
          v-for="(item, index) in items" :key="index" :src="item" alt="">
        </div>
        <div class="more-btn">
          <button  v-if="showLess" class="primary-btn" @click="loadLess">Show Less</button>
          <button v-else class="primary-btn more-btn_show" @click="loadMore">Show More</button>
        </div>
      </div>
    </div>
    <!-- Client Say -->
    <div class="our-works_testimony">
      <div class="content-wrapper">
          <img class="quote" src="@/assets/icon/baseline-format-quote-black-48-dp.png" alt=""/>
          <div class="carosell-text">
            <div class="title medium-title bold">What They Said</div>
             <adx-testimonial-slider
              :slides="testimonySlide"
              :textStyle="testimonyStyle"
              :footerStyle="testimonyFooterStyle"/>
          </div>
      </div>
    </div>
    <!-- Enquiry -->
    <adx-enquiry-section/>
    <adx-footer></adx-footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import EnquirySection from '@/components/EnquirySection'
import TestimonialSlider from '@/components/TestimonialSlider'

export default {
  name: 'our-works-page',
  components: {
    'adx-header': Header,
    'adx-footer': Footer,
    'adx-enquiry-section': EnquirySection,
    'adx-testimonial-slider': TestimonialSlider,
  },
  props: {
    
  },
  data(){
    return {
      // hide: false,
      showLess: false,
      items:[],
      partners:[
        '/img/our-works/partners/aqua.png', '/img/our-works/partners/gojek.png','/img/our-works/partners/FF.png','/img/our-works/partners/linkaja.png','/img/our-works/partners/grab.png','/img/our-works/partners/realfood.png',
        '/img/our-works/partners/mypertamina.png', '/img/our-works/partners/samsung.png','/img/our-works/partners/blibli.png','/img/our-works/partners/moladin.png','/img/our-works/partners/polytron.jpg','/img/our-works/partners/mandiri.jpg',
        '/img/our-works/partners/wingsfood.jpg', '/img/our-works/partners/scarlett.png','/img/our-works/partners/tokopedia.jpg','/img/our-works/partners/bi.jpg','/img/our-works/partners/zamron.jpg','/img/our-works/partners/sosro.jpg',
        '/img/our-works/partners/bhinneka.png', '/img/our-works/partners/promag.png','/img/our-works/partners/adakami.jpg','/img/our-works/partners/alga.jpg','/img/our-works/partners/belimobilgue.jpg','/img/our-works/partners/jis.jpg',
        '/img/our-works/partners/nagata.jpg', '/img/our-works/partners/ovo.jpg','/img/our-works/partners/paragon.jpg','/img/our-works/partners/pluang.jpg','/img/our-works/partners/sampoernakayu.jpg','/img/our-works/partners/travelio.jpg',
        '/img/our-works/partners/venom.jpg', '/img/our-works/partners/wahyoo.jpg',],
      testimonySlide:[
        {
          text: '“The way ADX Asia can listen to our objectives is what we need. And serving real-time visibility progress for our thousand spots of branding should be the gold standard!”',
          footer: 'Edi Kurniawan, Head of Marketing Moladin',
          urlImg: '/img/testimony/Edi Moladin.jpg'
        },
        {
          text: '“Media partner yang punya end to end service dengan kualitas yang outstanding! Salah satu poin kunci adalah: teknologi pengukuran dan report yg detail & insightful. Good job ADX Asia!”',
          footer: 'Inesa Muliadi, Campaign Lead LinkAja',
          urlImg: '/img/testimony/Inesa Muliadi-Campaign Lead LinkAja.png'
        },
        {
          text: "“A brand campaign will be delivered well if it is accompanied by a good media partner. And a good media partner will give you a right data and a wonderful experience while exploring the great-advertising industry, and it is been proven with ADX Asia's work.”",
          footer: 'Fanny Octafiani, Brand Marketing Specialist of Realfood',
          urlImg: '/img/testimony/Fanny Realfood.jpg'
        },
        {
          text: "“It's been 2 years working with ADX, starting from events in Jakarta to almost all over Java. this proves that ADX has good responsibilities in various aspects. Fast response, good in weekly and monthly reporting and great problem solving made ADX becoming one of the most trusted partners we had.”",
          footer: 'Riyad Jasa Putra, Local Activation Manager of Frisian Flag',
          urlImg: '/img/testimony/Riyad Jasa Putra-Frisian Flag1.jpg'
        },
      ],
      testimonyStyle:{
        "font-size": "20px",
        "color": "black",
        "max-width" : "100%",
        "font-style": "italic"
      },
      testimonyFooterStyle: {
        "font-size": "14px",
        "color": "gray",
      }
    }
  },
  created(){
    this.items = this.partners.slice(0,16)
  },
  mounted(){
    
  },
  methods:{
    loadMore(){
      let totalData = this.items.length + 16
      console.log("partner",this.partners.length)
      console.log(totalData)
      if (totalData == this.partners.length){
        this.items = this.partners.slice(0,this.partners.length)
        this.showLess = true
      }else {
        this.items = this.partners.slice(0,totalData)
      }
    },
    loadLess(){
      this.showLess = false
      this.items = this.partners.slice(0,16)
    }
  }
}
</script>


<style lang='scss' scoped>
@import './index.scss';
</style> 
