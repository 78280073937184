<template>
  <footer>
    <div class="footer_container">
      <div class="footer_container_top">
        <div class="address-detail">
          <img src="@/assets/logo/logo-header@3x.png" alt="ADX Asia">
        <div class="address-detail_content">
          <div class="regular-font medium-bold font-black">Head Office : </div>
          <div class="regular-font font-gray">Jl. Muara Karang Blok D 7 Barat No 46, RT.7/RW.8, Pluit, 
            Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450</div>
        </div>
      </div>
      <div class="categories-wrapper">
      <div v-for="(category, index) in categories" :key="index" class="category">
        <div class= "title small-title bold font-black">{{category.title}}</div>
          <ul>
            <li v-for="(item, index) in category.items" :key="index" 
            class="footer-items" style="cursor: pointer;">
              <a class="regular-font font-gray" :href="item.path" :target=" item.newTab? '_blank' :''">{{item.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      </div>
      <div class="footer_container_bottom">
        <div class="line"></div>
        <div class="copy-right font-gray">Copyright © 2022 ADXASIA</div>
      </div>
    </div>
  </footer>
</template>x

<script>
export default {
  name: 'adx-footer',
  props: {
  },
  computed:{
   
  },
  data() {
    return {
      categories: [
        {
          title : "Our Products",
          items: [
            {
              name: "Metrics" ,
              path: '/metrics',
              newTab: true
            },
            {
              name: "Origami" ,
              path: '/origami',
              newTab: true
            }
          ]
        },
        {
          title : "Company",
          items: [
            {
              name: "About Us" ,
              path: '/about-us',
              newTab: false
            },
            {
              name: "Our Works" ,
              path: '/our-works',
              newTab: false
            }
          ]
        },
        {
          title : "Resource",
          items: [
          {
            name: "Contact Us" ,
            path: 'https://m5.gs/RUlSND',
            newTab: true
          }]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
