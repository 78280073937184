<template>
  <div class="modal">
    <div class="modal__background" @click.stop="closeModal"></div>
    <div class="modal__content" :class="{[type]:true, 'no-title':noTitle, 'no-title-top':noTitleTop}">
      <div :class="[headerMoreFilter ? 'modal__content__headerMoreFilter':'modal__content__header']">
        <template v-if="!hideClose">
          <button v-if="noTitle || noTitleTop" @click.stop="closeModal" class="btn-no-title"><i class="adx-ic_close"></i></button>
          <button v-else @click.stop="closeModal"><img src="@/assets/icon/close-black.png" alt="" class="close-btn"></button>
        </template>
        <div :class="['title','large-font',titleCenter ? 'title-center':'']">{{title}}</div>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'adx-modal',
  components: {
  },
  props: {
    type: {
      required: false
    },
    title: {
      required: false
    },
    color: {
      required: false
    },
    noTitle: {
      required: false,
      default: false
    },
    noTitleTop: {
      required: false,
      default: false
    },
    hideClose: {
      required: false,
      default: false
    },
    titleCenter:{
      required: false,
      default: false
    },
    headerMoreFilter:{
      required: false,
      default:false
    }
  },
  watch: {

  },
  beforeCreate(){
    
  },
  mounted() {
    document.body.style.overflow = "hidden"
    document.documentElement.style.overflow = "hidden"
    document.body.style.position = "relative" 
    document.documentElement.style.overflow = "relative"
    document.body.style.height = "100%" 
    document.documentElement.style.height = "100%"
    document.body.addEventListener('keyup', e => {
      if (e.key === "Escape") {
        this.closeModal()
      }
    })
  },
  unmounted() {
    document.body.style.overflow = '' 
    document.documentElement.style.overflow = ''
    document.body.style.position = '' 
    document.documentElement.style.overflow = ''
    document.body.style.height = '' 
    document.documentElement.style.height = ''
  },
  methods: {
    closeModal() {
      this.$emit('modal-close')
    }
  }
}
</script>

<style lang='scss'>
@import './index.scss';
</style>