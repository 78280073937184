import { createRouter, createWebHistory } from "vue-router"
import Home from '@/views/Home'
import AboutUs from '@/views/AboutUs'
import OurWorks from '@/views/OurWorks'
import ContactUs from '@/views/ContactUs'
import AllProjects from '@/views/OurWorks/Projects'
import Metrics from '@/views/OurWorks/Projects'
import Origami from '@/views/OurWorks/Projects'
import CaseStudy from '@/views/CaseStudy'
import NewOrigami from '@/views/Origami'
import NewMetrics from '@/views/Metrics'
import ComingSoon from '@/views/ComingSoon'

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/our-works",
    name: "our-works",
    component: OurWorks,
    redirect: { name: 'works-allprojects' },
    children: [
      {
        path: '/our-works/all-projects',
        name: 'works-allprojects',
        component: AllProjects,
        props: true
      },
      {
        path: '/our-works/metrics',
        name: 'metrics',
        component: Metrics
      },
      {
        path: '/our-works/origami',
        name: 'origami',
        component: Origami
      }
    ]
  },
  {
    path: "/case-study",
    name: "case-study",
    component: CaseStudy
  },
  {
    path: "/origami",
    name: "new-origami",
    component: NewOrigami
  },
  {
    path: "/metrics",
    name: "new-metrics",
    component: NewMetrics,
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: ComingSoon
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})


export default router
