<template>
  <GMapMap
    class="gmap"
    :center="center"
    :zoom="19"
    map-type-id="terrain"
    :options="options"
  >
    <GMapCluster >
      <GMapMarker
        :position="position"
      />
    </GMapCluster>
  </GMapMap>
</template>

<script>
export default {
    name:'adx-maps',
    data() {
        return {
            center: { lat: -6.118503224691975, lng: 106.77709540402459 },
            position: {lat: -6.118503224691975,lng: 106.77709540402459 },
            options: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            }
        };
    },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
