import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import VueGoogleMaps from "@fawmi/vue-google-maps";

// Materialize framework
import 'materialize-css/dist/css/materialize.min.css'

// Custom css
import './styles/base.scss'

import router from './router'

const emitter = mitt()// Initialize mitt
const app = createApp(App)

app.use(router)
// app.use(store)
app.use(VueGoogleMaps, {
    load: {
        key: "" // Untuk Google Maps API key
    }
})

app.config.globalProperties.emitter = emitter
app.mount('#app')
