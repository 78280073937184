import {initializeApp} from "firebase/app";
import {config} from '@/lib/config.js'

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  databaseURL: config.databaseURL,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId
}

function firebaseInit(){
  initializeApp(firebaseConfig)
}

export default firebaseInit