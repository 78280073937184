<template>
  <header :class="isSticky ? 'sticky' : 'transparent'">
    <div class="header_container">
      <div class="header_container__left">
        <a href="/" class="header_container__left__logo">
          <img
            v-if="!isSticky"
            src="@/assets/logo/adxasia-white@3x.png"
            alt="ADX Asia"
            style="width: 92px; height: 48px"
          />
          <img
            v-if="isSticky"
            src="@/assets/logo/logo-header@3x.png"
            alt="ADX Asia"
          />
        </a>
        <ul class="header_container__left__menu">
          <li :class="isSticky ? 'sticky-menu-list' : 'default-menu-list'">
            <router-link to="/about-us"><span>About Us</span></router-link>
          </li>
          <li
            :class="[
              'our-product',
              isSticky ? 'sticky-menu-list' : 'default-menu-list',
              isproductActive ? 'active-link' : '',
              isSticky&&isproductActive ? 'active-link border-red' : '',
            ]"
            @click="showDropdown"
          >
            <span>Our Product</span>
            <div
              :key="renderKey"
              v-if="isproductActive"
              class="dropdown-product"
            >
              <a href="/metrics" target="_blank">
                <div
                  class="card-product font-white font-regular"
                  style="background-image: url('/img/about-us/metrics/rectangle@3x.png'); background-size: cover;">
                  Metrics
                </div>
              </a>
              <a href="/origami" target="_blank">
                <div
                  class="card-product font-white font-regular"
                  style="background-image: url('/img/about-us/origami/rectangle@3x.png'); background-size: cover;">
                  Origami
                </div>
              </a>
            </div>
          </li>
          <li :class="isSticky ? 'sticky-menu-list' : 'default-menu-list'">
            <router-link to="/our-works"><span>Our Works</span></router-link>
          </li>
          <li :class="isSticky ? 'sticky-menu-list' : 'default-menu-list'">
            <a 
            href="https://drive.google.com/drive/folders/1lXgy1J4cjeExJLgVQwgQDstRdE2etPcB?usp=sharing" 
            target="_blank">
              <span>Newsletter</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="header_container__right">
        <a :href="contactUrl" target="_blank">
          <button class="base-font medium-bold font-red" 
          :class="isSticky? 'primary-btn': 'secondary-btn'"
          style="cursor:pointer">
          Contact Us
        </button>
        </a>
      </div>
      <div class="header_container__mobile-menu" @click="showMenu">
        <img v-if="isSticky" src="@/assets/icon/menu-bar-black.png" />
        <img v-else src="@/assets/icon/menu-bar-white.png" />
      </div>
      <transition name="heightTop">
        <div class="sideBarMenu" v-if="isToggle">
          <div class="title bold">
            <div>
              Menu
            </div>
            <div>
              <img src="@/assets/icon/close-black.png" alt="" @click="showMenu">
            </div>
          </div>
          <div class="menu">
            <div class="option">
              <a href="/" class="base-font medium-bold font-gray"
                >Home</a
              >
              <a href="/about-us" class="base-font medium-bold font-gray"
                >About Us</a
              >
              <div class="dropdownProduct base-font medium-bold font-gray" @click="showDropdown">
                Our Product
                <div v-if="isproductActive">
                  <div class="mobile-product">
                    <a href="/metrics" target="_blank" class="base-font medium-bold font-gray">Metrics</a>
                    <a href="/origami" target="_blank" class="base-font medium-bold font-gray">Origami</a>
                  </div>
                </div>
              </div>
              <a href="/our-works" class="base-font medium-bold font-gray"
                >Our Works</a
              >
              <a 
              href="https://drive.google.com/drive/folders/1lXgy1J4cjeExJLgVQwgQDstRdE2etPcB?usp=sharing" 
              target="_blank" 
              class="base-font medium-bold font-gray">
                Newsletter
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
export default {
  name: "adx-header",
  props: {},
  data() {
    return {
      isToggle: false,
      isSticky: false,
      isproductActive: false,
      renderKey: 0,
      contactUrl: "https://m5.gs/RUlSND"
    }
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      const header = e.srcElement.getElementById("adx-header");
      const sticky = header.offsetTop;
      this.isSticky = window.pageYOffset > sticky ? true : false;
    },
    showDropdown() {
      this.isproductActive = !this.isproductActive;
      this.renderKey++;
    },
    showMenu() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
