<template>
  <div class="single-text-slider" :style="[`background-image: url(${url})`]">
  <agile 
  :initialSlide="0"
  :autoplay="autoplay" 
  :navButtons="false" 
  :dots="true"
  :speed="300"
  @after-change="showCurrentSlide($event)">
      <div v-for="(slide, index) in slides" :key="index">
        <div v-if="slide.header" class="font-white header">{{slide.header}}</div>
        <div v-if="slide.title" class="font-white bold large-title main-title" 
          :style="mainTitleStyle">{{slide.title}}</div>
      </div>
    </agile>
  </div>
</template>

<script>

import { VueAgile } from 'vue-agile'

export default {
  name: 'adx-single-text-slider',
  components: {
  'agile': VueAgile 
  },
  props: {
    slides: {
      type: Array,
      required: false,
    },
    autoplay:{
      type: Boolean,
      required: false,
      default: true
    },
    mainTitleStyle: {
      required: false,
      type: Object,
      default: () => {
        return {
          "font-size": "32px",
          "background" : "red"
        }
      },
    },
  },
  data() {
    return {
     url : ""
    }
  },
  mounted(){

  },
  computed:{
   
  },
  methods :{
    showCurrentSlide(event){
      this.url = this.slides[event.currentSlide].urlImg 
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>