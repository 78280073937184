<template>
  <div class="case-study">
    <div
      class="case-study_banner"
      :style="[`background-color: ${caseData.background}`]"
    >
      <div class="header-case-study">
        <div class="logo-wrapper">
          <a href="/" class="logo">
            <img src="@/assets/logo/adxasia-white@3x.png" alt="ADX Asia" />
          </a>
        </div>
        <div
          class="action-wapper"
          @click="backtoHome()"
          style="cursor: pointer"
        >
          <div class="font-white font-reguler">Close</div>
          <div class="font-white font-reguler">x</div>
        </div>
      </div>
      <div class="banner-title">
        <div class="title large-title regular-font font-white bold">
          {{ caseData.bannerTitle }}
        </div>
        <div class="desc font-regular font-white">
          {{ caseData.bannerDesc }}
        </div>
      </div>
    </div>
    <!-- About Campaign -->
    <div class="case-study_about-campaign">
      <div class="title medium-title bold">About This Campaign</div>
      <div class="desc font-gray regular-font">
        {{ caseData.aboutCampaign }}
      </div>
    </div>
    <!-- Spot and Trafic -->
    <div class="case-study_spot-traffic">
      <div class="left-content">
        <div class="case-image" v-for="(item, index) in caseData.trafficImg" :key="index"> 
          <img
            :src="item"
            alt=""
          />
        </div>
      </div>
      <div class="right-content">
        <div
          v-if="!caseData.spotTrafic.desc1"
          class="title font-black bold small-title"
        >
          Spots & Traffics
        </div>
        <div class="desc font-gray regular-font" style="white-space: pre-wrap">
          {{ caseData.spotTrafic.desc }}
        </div>
        <div
          v-for="(item, index) in caseData.spotTrafic.spot"
          :key="index"
          class="spot font-gray regular-font"
        >
          {{ item }}
        </div>
        <div class="desc1 bold regular-font" style="white-space: pre-wrap">
          {{ caseData.spotTrafic.desc1 }}
        </div>
        <div
          v-if="!caseData.spotTrafic.desc1"
          style="margin-bottom: 30px"
        ></div>
        <div
          v-html="caseData.spotTrafic.desc2"
          class="desc font-gray regular-font"
          style="white-space: pre-wrap"
        ></div>
      </div>
    </div>
    <!--  Case Study Other -->
    <div class="case-study_other">
      <div class="title bold font-black">More case study:</div>
      <div class="cards-wrapper">
        <div
          class="brand-thumbnail"
          v-for="(item, index) in caseData.moreStudies"
          :key="index"
        >
          <img
            @click="gotoCaseStudy(item.brand)"
            :src="item.image"
            alt=""
            style="cursor: pointer"
          />
        </div>
      </div>
    </div>
    <!-- Enquiry  -->
    <adx-enquiry-section />
    <adx-footer></adx-footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import EnquirySection from "@/components/EnquirySection";

export default {
  name: "case-study-page",
  components: {
    "adx-footer": Footer,
    "adx-enquiry-section": EnquirySection,
  },
  props: {},
  data() {
    return {
      caseData: null,
      caseStudy: {
        realfood: {
          bannerTitle: "Realfood - Billboard",
          bannerDesc:
            "We provide Realfood’s OOH needs to introducing their newest product. There are 8 OOH spots around Jakarta, Surabaya, Bekasi, Tangerang area. Along this campaign, Realfood has reached more than 25,000,000+  of total vehicle traffics.",
          aboutCampaign:
            "Realfood wants to not only introduce their newest product line-up, Realfood Jelly but also their newest brand ambassador, a famous Korean idol, Song Kang. To support their online campaign, Realfood also ran an offline campaign in the form of out of home advertising.",
          spotTrafic: {
            desc: "ADX Asia provided many OOH spots for Realfood to help them reach their objectives around Jabodetabek & Surabaya area.",
            // spot: [
            //   "• Jakarta | 3 spots",
            //   "• Surabaya | 3 spots",
            //   "• Tangerang | 1 spots",
            //   "• Bekasi | 1 spot",
            // ],
            desc2:
              'Through this campaign, Realfood has <b class= "font-black bold">reached more than 25,000,000+ vehicle traffics </b>due to our choice of location with high traffic yet affordable budgets. As a result, Realfood campaign is deemed efficient in terms of budget due to the lower Cost Per Vehicle number for their campaign.',
          },
          trafficImg: [
            "/img/case-study-realfood/case-study-realfood-pic1/case-study-linkaja@2x.png",
            "/img/case-study-realfood/case-study-realfood-pic2/case-study-frisian-flag@2x.png",
            "/img/case-study-realfood/case-study-realfood-pic3/case-study-aqua@2x.png",
          ],
          moreStudies: [
            {
              image: "/img/case-study-aqua/case-study-aqua@2x.png",
              brand: "aqua",
            },
            {
              image:
                "/img/case-study-frisian-flag/case-study-frisian-flag@2x.webp",
              brand: "frisianflag",
            },
            {
              image: "/img/case-study-linkaja/case-study-linkaja@2x.png",
              brand: "linkaja",
            },
          ],
          background: "#0e6137",
        },
        linkaja: {
          bannerTitle: "LinkAja - Billboard",
          bannerDesc:
            "We support LinkAja’s campaign by providing OOH placement in 10 spots around Jabodetabek for 3 months period. Along this campaign, LinkAja has reached more than 70,000,000+ of total vehicle traffics.",
          aboutCampaign:
            "To increase brand awareness and exposure, LinkAja ran an out-of-home advertising campaign to gain wider audience via offline promotion. LinkAja chose ADX Asia to help them reach their goals.",
          spotTrafic: {
            desc: "ADX Asia provides many spots for OOH placement around big cities of Java for LinkAja.",
            desc2:
              'All of LinkAja’s OOH spot is placed at a strategic area with large size, clear visibility, and high traffic to meet the objectives. Through this campaign, LinkAja has <b class= "font-black bold">reached more than 70,000,000+ vehicle traffics.</b> The numbers  are real traffic data provided by our Computer Vision Camera (CVC).',
          },
          trafficImg: [
            "/img/case-study-linkaja/case-study-linkaja-pic1/case-study-linkaja@2x.png",
            "/img/case-study-linkaja/case-study-linkaja-pic2/case-study-frisian-flag@2x.png",
            "/img/case-study-linkaja/case-study-linkaja-pic3/case-study-aqua@2x.png",
          ],
          moreStudies: [
            {
              image: "/img/case-study-realfood/case-study-realfood@2x.png",
              brand: "realfood",
            },
            {
              image: "/img/case-study-aqua/case-study-aqua@2x.png",
              brand: "aqua",
            },
            {
              image:
                "/img/case-study-frisian-flag/case-study-frisian-flag@2x.webp",
              brand: "frisianflag",
            },
          ],
          background: "#e82529",
        },
        aqua: {
          bannerTitle: "Aqua - Branding Implementation",
          bannerDesc:
            "We provide end-to-end Point of Sales branding management nationwide at our retail partners stores (Alfamart & Indomaret) which cover production, distribution, installation and removal process. We cover 19,000+ retail stores in 200+ cities in Indonesia’s big provinces.",
          aboutCampaign:
            "To attract customers’ attention directly, we provide Point of Sale Branding Management for AQUA at their retail partners stores (Alfamart & Indomaret) nationwide.",
          spotTrafic: {
            desc: "ADX Asia serves AQUA’s needs from:",
            desc1: "Distribution -> Installation -> POSM Audit.",
            desc2:
              'In total, we coverage 19,000+ retail stores in 200+ cities around big province of Indonesia (such as Jawa, Sumatera, Kalimantan, and Sulawesi) in short period due to their promotion timeline. <b class= "font-black bold">We complete high number of stores in short period with 15% POSM</b> waste reduction because our system managed to find double data & closed stores that allow us to request for replacement in order to optimize the branding impact \n<b class="font-black bold">ADX Asia is well-proven in handling various types of POSM such as chiller stickers, bottle tags, and others.</b>',
          },
          trafficImg: [
            "/img/case-study-aqua/case-study-aqua-pic1/case-study-linkaja@2x.png",
            "/img/case-study-aqua/case-study-aqua-pic2/case-study-frisian-flag@2x.png",
            "/img/case-study-aqua/case-study-aqua-pic3/case-study-aqua@2x.png",
          ],
          moreStudies: [
            {
              image: "/img/case-study-realfood/case-study-realfood@2x.png",
              brand: "realfood",
            },
            {
              image: "/img/case-study-linkaja/case-study-linkaja@2x.png",
              brand: "linkaja",
            },
            {
              image:
                "/img/case-study-frisian-flag/case-study-frisian-flag@2x.webp",
              brand: "frisianflag",
            },
          ],
          background: "#273f8a",
        },
        frisianflag: {
          bannerTitle: "Frisian Flag - Branding Activation",
          bannerDesc:
            "We provide a customer engagement marketing activities for Frisian Flag with the objective of raising brand awareness. In total, we acquired more than 380,000+ customers in 2,500+ locations around big provinces in Indonesia.",
          aboutCampaign:
            "Frisian Flag has many products line up to offer and they wanted to focus on the promotions for 4 of their line up products namely Omela, RTD, SCM, and Kompleta. ADX Asia helped them to create customer engagement marketing activities that can be implemented to increase brand awareness and brand positioning.",
          spotTrafic: {
            desc: "We provided Activation with SPG. Including 4 in 1 activities:",
            desc1:
              "Sampling Product -> Selling Product -> Customer Survey -> Spreading",
            desc2:
              "In total, we acquired more than 380,000+ customers in 2,500+ locations around Jabodetabek, Bandung, Semarang, Yogyakarta, and East Java. We provide real time monitoring and combine 4 in 1 activation at many good locations.",
          },
          trafficImg: [
            "/img/case-study-frisian-flag/case-study-frisian-flag-pic1/case-study-linkaja@2x.png",
            "/img/case-study-frisian-flag/case-study-frisian-flag-pic2/case-study-frisian-flag@2x.png",
            "/img/case-study-frisian-flag/case-study-frisian-flag-pic3/case-study-aqua@2x.png",
          ],
          moreStudies: [
            {
              image: "/img/case-study-realfood/case-study-realfood@2x.png",
              brand: "realfood",
            },
            {
              image: "/img/case-study-linkaja/case-study-linkaja@2x.png",
              brand: "linkaja",
            },
            {
              image: "/img/case-study-aqua/case-study-aqua@2x.png",
              brand: "aqua",
            },
          ],
          background: "#3ab1e4",
        },
      },
    };
  },
  created() {
    const brand = this.$route.params.brand;
    this.caseData = this.getBrandData(brand);
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    getBrandData(brand) {
      let data = null;
      switch (brand) {
        case "linkaja":
          data = this.caseStudy.linkaja;
          break;
        case "realfood":
          data = this.caseStudy.realfood;
          break;
        case "aqua":
          data = this.caseStudy.aqua;
          break;
        case "frisianflag":
          data = this.caseStudy.frisianflag;
          break;
        default:
          data = this.caseStudy.realfood;
          break;
      }
      return data;
    },
    backtoHome() {
      this.$router.push({ name: "home" });
    },
    gotoCaseStudy(brand) {
      window.scrollTo(0,0);
      this.$forceUpdate();
      this.caseData = this.getBrandData(brand);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
