<template>

<div class="coming-soon">
  <img class="adx-logo" src="@/assets/logo/adxasia-white@3x.png" alt="adx-logo">
  <div class="font-white title">
    Coming Soon
  </div>
  <div class="font-white desc">
    Our new landing page for an awesome Product 
    <br>
  ready to launch.
  </div>
</div>

</template>

<script>

export default{
  name: "coming-soon",
  props: {},
  data() {
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
