<template>
    <div class="app-container">
      <router-view ></router-view>
    </div>
</template>

<script>
import M from 'materialize-css'
import firebaseInit from './firebaseConf'

export default {
  name: 'App',
  components: {
   
    
  },
  mounted() {
    M.AutoInit()
    firebaseInit()
  },
}
</script>

