<template>
  <Carousel :breakpoints="breakpoints" :wrap-around="true">
    <Slide v-for="slide in slides" :key="slide">
      <div class="carousel__item">
        <div class="wrapper">
          <div class="slider-img">
            <img :src="slide.slideImg" alt="" />
          </div>
          <div class="right-content-slider">
            <div class="loop-number font-white regular-font">
              {{ slide.slideNum }}
            </div>
            <div class="bold slider-title">
              {{ slide.slideTitle }}
            </div>
            <div class="slide-desc regular-font medium-bold font-gray">
              {{ slide.slideDesc }}
            </div>
          </div>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "metrics-slider",
  components: {
    Carousel,
    Navigation,
    Slide,
  },
  props: {
    slides: { type: Array, required: true },
  },
  data: () => {
    return {
      breakpoints: {
        300: {
          itemsToShow: 1,
        },
        769:{
          itemsToShow: 2.0001,
        },
        1140: {
          itemsToShow: 2.0001,
        },
        1441: {
          itemsToShow: 2.3,
        },
      },
    };
  },
});
</script>

<style lang="scss">
@import "./index.scss";
</style>
