<template>
  <div class="about-us">
    <adx-header id="adx-header"></adx-header>
    <div
      class="about-us_banner"
      style="
        background-image: url('/img/about-us/banner/rectangle@2x.png');
        background-size: cover;
      "
    >
      <div class="banner-title">
        <div class="font-white reular-font about-us_info">ABOUT US</div>
        <div class="large-title font-white bold about-us_title">
          Introducing ADX Asia
        </div>
        <div class="desc font-white">
          The solution for your OOH advertising & marketing operation.
        </div>
      </div>
    </div>
    <!-- Company Overview -->
    <div class="about-us_company-overview">
      <div class="title medium-title bold">Company Overview</div>
      <span class="font-gray regular-font overview-desc"
        >Greetings, we’re ADX Asia. A startup company with a mission to empower
        a smarter Out-of-Home experience through technology and innovation at
        scale. From project planning, executing, monitoring, and measurement, we
        are a one stop solution for your OOH advertising & marketing operation.
        <br>
        With ADX Asia, delivering your campaign will be more
        <strong class="font-black">effective</strong>, more
        <strong class="font-black">efficient</strong>, and more
        <strong class="font-black">scalable</strong> than ever before.</span
      >
    </div>
    <!-- Vision Mision -->
    <div class="about-us_vision-mision">
      <div class="about-us_vision-mision_left-content">
        <div class="vision">
          <div class="font-black bold medium-title vision-title">
            We have 2 product suites to fulfill your campaign needs
          </div>
          <div class="desc font-gray regular-font">
            Our product suites are tailored to your marketing needs, from large
            format to massive locations, from awareness to engagement and
            conversion. Anytime, anywhere and anyone because
            <strong class="font-black">Ads is for Everyone</strong>!
          </div>
        </div>
      </div>
      <div class="about-us_vision-mision_right-content">
        <div
          class="card-item"
          style="
            background-image: url('/img/about-us/metrics/rectangle@2x.png');   
          "
        >
          <div class="content">
            <div class="small-title font-white title bold">Metrics</div>
            <div class="desc-product font-white regular-font metrics">
              Solutions for a large-format media campaign needs with multitude
              of channels.
            </div>
            <div class="learn-more">
              <button
                class="secondary-btn font-red medium-bold regular-font"
                @click="gotoProduct('metrics')"
                style="cursor: pointer"
              >
                Learn Metrics
              </button>
            </div>
          </div>
        </div>
        <div
          class="card-item"
          style="
            background-image: url('/img/about-us/origami/rectangle@2x.png');
          "
        >
          <div class="content">
            <div class="small-title font-white title bold">Origami</div>
            <div class="desc-product origami font-white regular-font">
              Operational solutions for your any operational aspects needs.
            </div>
            <div class="learn-more">
              <button
                class="secondary-btn font-red medium-bold regular-font learn-btn"
                @click="gotoProduct('origami')"
                style="cursor: pointer"
              >
                Learn Origami
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Milestones -->
    <div class="about-us_milestone">
      <div class="title medium-title bold font-black">ADX Asia Milestones</div>
      <div class="col">
        <div class="activity-card-wrapper">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="activity-card"
          >
            <div class="card">
              <div class="small-title bold font-blue milestone-title">
                {{ item.year }}
                <br />
                {{ item.title }}
              </div>
              <div class="desc-item font-gray regular-font">
                {{ item.desc }}
              </div>
            </div>
            <div v-if="item.desc1 && item.desc2" class="card">
              <div class="desc-item font-gray regular-font">
                {{ item.desc1 }}
              </div>
            </div>
            <div v-if="item.desc1 && !item.desc2" class="card-last">
              <div class="desc-item font-gray regular-font">
                {{ item.desc1 }}
              </div>
            </div>
            <div v-if="item.desc2" class="card-last">
              <div class="desc-item font-gray regular-font">
                {{ item.desc2 }}
              </div>
            </div>
            <div v-if="index != items.length - 1" class="next-icon">
              <img src="@/assets/icon/chevron-right.png" alt="" />
            </div>
          </div>
        </div>

        <!-- Tablet View Only -->
        <div class="activity-card-wrapper-tablet">
          <div
            v-for="(item, index) in tabletItems"
            :key="index"
            class="activity-card"
          >
            <div class="card">
              <div class="small-title bold font-blue milestone-title">
                {{ item.year }}
                <br />
                {{ item.title }}
              </div>
              <div class="desc-item font-gray regular-font">
                {{ item.desc }}
              </div>
            </div>
            <div v-if="item.desc1 && item.desc2" class="card">
              <div class="desc-item font-gray regular-font">
                {{ item.desc1 }}
              </div>
            </div>
            <div v-if="item.desc1 && !item.desc2" class="card-last">
              <div class="desc-item font-gray regular-font">
                {{ item.desc1 }}
              </div>
            </div>
            <div v-if="item.desc2" class="card-last">
              <div class="desc-item font-gray regular-font">
                {{ item.desc2 }}
              </div>
            </div>
            <div class="next-icon">
              <img v-if="index == 0" src="@/assets/icon/chevron-right.png" alt="" />
              <img v-if="index == 1" src="@/assets/icon/chevron-right.png" alt="" style="transform: rotate(90deg)"/>
              <img v-if="index == 2" src="@/assets/icon/chevron-right.png" alt="" style="transform: rotate(180deg)"/>
              <img v-if="index == 3" src="@/assets/icon/chevron-right.png" alt="" style="transform: rotate(180deg)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="desciption">
        <div class="regular-font bold title">
          There are many improvements and innovation we have made for more than
          5 years+
        </div>
        <div class="regular-font font-gray desc">
          We are forever thankful for all of the partners and brands who already
          trust ADX since the beginning. And the hard work of our team that
          allow ADX asia to make significant progress year by year until it
          becomes what it is now. But still, there will be many more innovations
          to be reated to satisfy
          <strong class="font-black">everyone's needs</strong>.
        </div>
      </div>
    </div>

    <!-- People Behind -->
    <div class="about-us_people">
      <div class="title medium-title bold font-blue">The people behind</div>
      <div class="cards-wrapper">
        <div v-for="(item, index) in people" :key="index" class="image-wrapper">
          <img :src="item.image" alt="" />
          <div class="title-name bold">{{ item.name }}</div>
          <div class="title-name">{{ item.position }}</div>
        </div>
      </div>
    </div>
    <adx-enquiry-section />
    <adx-footer></adx-footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import EnquirySection from "@/components/EnquirySection";

export default {
  name: "about-us-page",
  components: {
    "adx-header": Header,
    "adx-footer": Footer,
    "adx-enquiry-section": EnquirySection,
  },
  props: {},
  data() {
    return {
      items: [
        {
          year: "2016",
          title: "The Born of ADX Asia",
          desc: "ADX became the pioneer for Digital Screen Advertising in F&B and Lifestyle outlets.",
          desc1:
            "Provide the technology such as cloud-based content management and monitoring dashboard for client.",
        },
        {
          year: "2017-2019",
          title: "More Inventories, More Opportunities!",
          desc: "Adding more inventories by collaborating with big external partners: Become the official advertising partner for Pertamina Retail (Gas Station), Garuda Indonesia & Sriwijaya Air (Inflight).",
          desc1:
            "Partnering with established brands; Lion Super Indo, Boga Group, Warunk Upnormal, Bebek Kaleyo, Irwan Team, etc. with more than 1,000+ distribution on digital screen.",
          desc2:
            "Expanding collaboration with Media Owner with various OOH channel like Billboard, Transportation, Mall, Airport, Office, Cinema, etc, for more than 300+ brands.",
        },
        {
          year: "2020-2021",
          title: "Leverage Out-of-Home Experience",
          desc: "Improving our Metrics technology for every OOH campaign by adding Traffic Measurement Dashboard to serve more in-depth data from our Computer-vision Camera.",
          desc1:
            "To ensure on-going business, we create Origami: a solution for marketing operation such as Branding Implementation & Event Activation with operational task tracking platform freshly made by ADX Asia.",
          desc2:
            "On 2021, we survived and bounce back from pandemic impact & hit a new breaking record sales throughout ADX Asia history",
        },
        {
          year: "2022",
          title: "Technology for Everyone",
          desc: "Expanding the business through Metrics by providing technology services for Media Owner and Media Agency: Traffic Measurement.",
          desc1:
            "Expanding the business by making Origami as operational partner for FMCG, F&B, Retail businesses and many more.",
          desc2:
            "Offering the Origami’s technology as Software Service (SaaS) to various businesses to manage their operational systems.",
        },
      ],
      tabletItems: [
        {
          year: "2016",
          title: "The Born of ADX Asia",
          desc: "ADX became the pioneer for Digital Screen Advertising in F&B and Lifestyle outlets.",
          desc1:
            "Provide the technology such as cloud-based content management and monitoring dashboard for client.",
        },
        {
          year: "2017-2019",
          title: "More Inventories, More Opportunities!",
          desc: "Adding more inventories by collaborating with big external partners: Become the official advertising partner for Pertamina Retail (Gas Station), Garuda Indonesia & Sriwijaya Air (Inflight).",
          desc1:
            "Partnering with established brands; Lion Super Indo, Boga Group, Warunk Upnormal, Bebek Kaleyo, Irwan Team, etc. with more than 1,000+ distribution on digital screen.",
          desc2:
            "Expanding collaboration with Media Owner with various OOH channel like Billboard, Transportation, Mall, Airport, Office, Cinema, etc, for more than 300+ brands.",
        },
        {
          year: "2022",
          title: "Technology for Everyone",
          desc: "Expanding the business through Metrics by providing technology services for Media Owner and Media Agency: Traffic Measurement.",
          desc1:
            "Expanding the business by making Origami as operational partner for FMCG, F&B, Retail businesses and many more.",
          desc2:
            "Offering the Origami’s technology as Software Service (SaaS) to various businesses to manage their operational systems.",
        },
        {
          year: "2020-2021",
          title: "Leverage Out-of-Home Experience",
          desc: "Improving our Metrics technology for every OOH campaign by adding Traffic Measurement Dashboard to serve more in-depth data from our Computer-vision Camera.",
          desc1:
            "To ensure on-going business, we create Origami: a solution for marketing operation such as Branding Implementation & Event Activation with operational task tracking platform freshly made by ADX Asia.",
          desc2:
            "On 2021, we survived and bounce back from pandemic impact & hit a new breaking record sales throughout ADX Asia history",
        },
      ],
      people: [
        {
          image: "/img/about-us/elbert_toha/rectangle@2x.png",
          name: "Elbert Toha",
          position: "CEO & Founder",
        },
        {
          image: "/img/about-us/eko_paulus/rectangle@2x.png",
          name: "Eko Paulus",
          position: "CCO & Co-Founder",
        },
        {
          image: "/img/about-us/steven_wang/rectangle@2x.png",
          name: "Steven Wang",
          position: "Managing Director",
        },
      ],
    };
  },
  mounted() {
    
  },
  methods: {
    gotoProduct(target){
      if(target == 'metrics'){
        let route = this.$router.resolve({path: '/metrics'});
        window.open(route.href, '_blank');
      }
      else if (target == 'origami'){
        let route = this.$router.resolve({path: '/origami'});
        window.open(route.href, '_blank');
      }
      
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
