<template>
    <div class="input-wrapper">
        <span class="regular-font medium-bold">{{customLabel}}</span><span class="regular-font medium-bold" v-if="!isRequired"> (Optional)</span>
        <input v-if="!isTextarea" class="input-field" :placeholder="customPlaceholder" :style="inputWidth" v-model="inputValue" />
        <textarea v-if="isTextarea" :placeholder="customPlaceholder" v-model="inputValue" ></textarea>
    </div>
</template>

<script>
export default {
    name: 'adx-input-text',
    props: {
        entryName:{
            type: String,
            required: true
        },
        customLabel:{
            type: String,
            required: true
        },
        customPlaceholder:{
            type: String,
            required: true
        },
        customWidth:{
            type: String,
            required: false,
            default: "737"
        },
        isTextarea:{
            type: Boolean,
            required: false
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        inputWidth(){
            return `width: ${this.customWidth}px`
        }
    },
    methods: {
        collectData(){
            const data = {}
            data[this.inputName] = this.inputValue
            return data
        }
    },
    data(){
        return {
            inputName: this.entryName,
            inputValue: ""
        }
    }
}
</script>

<style lang="scss" scope>
@import './index.scss';
</style>