<template>
    <div class="contact-us">
        <adx-header id="adx-header"/>
        <div class="contact-us_banner">
            <div class="banner-title">
                <span class="large-title regular-font bold">We are here for you.<br/>Say hi.</span>
            </div>
            <div class="banner-subtitle">
                <span class="regular-font font-gray">It’s easy to reach us. And it’s free. Two things everyone loves.<br/>We’re ready to take your business to the next level.<br/>Feel free to reach out to any and all of us – we’re generally pretty friendly. Except when we are hungry.</span>
            </div>
        </div>
        <div class="contact-us_main">
            <div class="contact-us_main_left-content">
                <div class="head-office-section">
                    <div class="head-office-section_header">
                        <div class="icon-wrapper">
                            <span class="material-icons">maps_home_work</span>
                        </div>
                        <div class="title-wrapper">
                            <span class="small-title bold">Head Office</span>
                        </div>
                    </div>
                    <div class="head-office-section_content">
                        <div class="content-wrapper">
                            <span class="regular-font font-gray">Jl. Muara Karang Blok D 7 Barat No 46, RT.7/RW.8, Pluit, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14450.</span>
                        </div>
                    </div>
                </div>
                <div class="phone-section">
                    <div class="phone-section_header">
                        <div class="icon-wrapper">
                            <span class="material-icons">phone</span>
                        </div>
                        <div class="title-wrapper">
                            <span class="small-title bold">Phone</span>
                        </div>
                    </div>
                    <div class="phone-section_content">
                        <div class="content-wrapper">
                            <span class="regular-font font-gray">021 - 6614822</span>
                        </div>
                    </div>
                </div>
                <div class="maps-section">
                    <div class="maps-section_header">
                        <div class="icon-wrapper">
                            <span class="material-icons">location_on</span>
                        </div>
                        <div class="title-wrapper">
                            <span class="small-title bold">Find us on maps</span>
                        </div>
                    </div>
                    <div class="maps-section_content">
                        <div class="content-wrapper">
                            <adx-maps class="maps" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact-us_main_form">
                <div class="contact-us_main_form_title">
                    <span class="small-title bold">Send Message</span>
                </div>
                <div class="contact-us_main_form_desc">
                    <span class="regular-font font-gray">Lets talk and make inquiry, our sales team will reach you as soon as possible after we received your message.</span>
                </div>
                <div class="contact-us_main_form_input-wrapper">
                    <adx-input-text 
                        entryName="subject"
                        customLabel="Subject" 
                        customPlaceholder="Select subject message" 
                        ref="subjectField" 
                    />
                </div>
                <div class="contact-us_main_form_input-wrapper">
                    <adx-input-text 
                        entryName="fullname"
                        customLabel="Fullname" 
                        customPlaceholder="First name and last name" 
                        ref="fullnameField" 
                    />
                </div>
                <div class="contact-us_main_form_input-wrapper">
                    <adx-input-text 
                        entryName="email"
                        customLabel="Email address" 
                        customPlaceholder="E.g someone@mail.com (make sure your active email address)" 
                        ref="emailField" 
                    />
                </div>
                <div class="contact-us_main_form_input-wrapper-grouped">
                    <div class="contact-us_main_form_input-wrapper-grouped_item">
                        <adx-input-text 
                            entryName="phone"
                            customLabel="Phone number" 
                            customPlaceholder="E.g +62812xxxxxx12" 
                            ref="phoneField" 
                            customWidth="350" 
                        />
                    </div>
                    <div class="contact-us_main_form_input-wrapper-grouped_item">
                        <adx-input-text 
                            entryName="company"
                            customLabel="Company" 
                            customPlaceholder="Your company name" 
                            ref="companyField" 
                            customWidth="350" 
                            :isRequired="false"
                        />
                    </div>
                </div>
                <div class="contact-us_main_form_input-wrapper-textarea">
                    <adx-input-text 
                        entryName="message"
                        customLabel="Message" 
                        customPlaceholder="Type your message here maximum 250 characters" 
                        ref="messageField" 
                        :isTextarea="true"
                    />
                </div>
                <div class="button-wrapper">
                    <button class="primary-btn" @click="sendMessage">Send now</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Maps from '@/components/Maps'
import InputText from '@/components/InputText'

export default {
    name: "contact-us-page",
    components: {
        'adx-header': Header,
        'adx-maps': Maps,
        'adx-input-text': InputText
    },
    data(){
        return {
            
        }
    },
    methods: {
        sendMessage(){
            const data = {
                ...this.$refs['subjectField'].collectData(),
                ...this.$refs['fullnameField'].collectData(),
                ...this.$refs['emailField'].collectData(),
                ...this.$refs['phoneField'].collectData(),
                ...this.$refs['companyField'].collectData(),
                ...this.$refs['messageField'].collectData(),
                }
            console.log(data)
        }
    }
}
</script>

<style lang="scss" scoped>
@import './index.scss'
</style>